import MutationSummary from 'mutation-summary'
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

const initBackground = element => {
  const setClassBasedOffWidth = () => {
    if (element.offsetHeight > element.offsetWidth) {
      element.classList.remove('bg-dots-vertical')
      element.classList.add('bg-dots-horizontal')
    } else {
      element.classList.remove('bg-dots-horizontal')
      element.classList.add('bg-dots-vertical')
    }
  }

  const resizeObserver = new ResizeObserver(setClassBasedOffWidth)
  resizeObserver.observe(element)
  setClassBasedOffWidth()
}

const init = () => {
  const selector = '.bg-dots'
  document.querySelectorAll(selector).forEach(initBackground)

  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(initBackground)
    },
    queries: [{ element: selector }]
  })
}

if (document.readyState == 'loading')
  document.addEventListener('DOMContentLoaded', init)
else
  setTimeout(init, 1)
