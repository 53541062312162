import { debounce } from 'debounce'
import remToPx from 'src/utils/remToPx'

import { MENU_SELECTOR, CONTENT_SELECTOR, DURATION } from './constants'

const timeouts = {
  timeouts: [],

  set (callback, ms) {
    const timeout = {
      callback,
      timeout: setTimeout(() => {
        this.timeouts = this.timeouts.filter(t => t != timeout)
        callback()
      }, ms)
    }
    this.timeouts.push(timeout)
  },

  flush () {
    this.timeouts.forEach(({ timeout, callback }) => {
      clearTimeout(timeout)
      callback()
    })
    this.timeouts = []
  }
}

const getStableRect = menu => {
  const rect = !menu.style.transform && menu.cachedRect || menu.getBoundingClientRect()
  if (!menu.style.transform)
    menu.cachedRect = rect
  return rect
}

const handleResize = () =>
  document.querySelectorAll(`${MENU_SELECTOR} .dropdown-menu`)
  .forEach(menu => menu.cachedRect = null)

addEventListener('resize', debounce(handleResize, 66))

export const resetFancy = () =>
  document.querySelector('.doing-the-fancy')?.classList.remove('doing-the-fancy')

export const makeFancy = (toggle, menu, { reverse = false }={}) => {
  timeouts.flush()

  const toggleRect = toggle.getBoundingClientRect()
  const navbar = menu.closest(MENU_SELECTOR)
  const navbarRect = navbar.getBoundingClientRect()
  const rect = getStableRect(menu)
  const source = navbar.querySelector(
    `.dropdown-menu.shown:not([aria-labelledby="${menu.getAttribute('aria-labelledby')}"])`
  )

  const offcanvasBody = menu.closest(`${MENU_SELECTOR}-offcanvas-body`)
  const background = offcanvasBody.querySelector(`${MENU_SELECTOR}-background`)
  const arrow = background.querySelector(`${MENU_SELECTOR}-arrow`)
  const bgTransform = `translateX(${rect.left - navbarRect.left}px)`
  const arrowTransform = `translate(${toggleRect.width / 2}px, -50%) rotate(45deg)`

  offcanvasBody.classList.add('doing-the-fancy')
  background.style.width = rect.width + 'px'
  background.style.height = rect.height - remToPx(0.5) + 'px'

  if (source) {
    const menuContent = menu.querySelector(CONTENT_SELECTOR)
    const sourceContent = source.querySelector(CONTENT_SELECTOR)
    const sourceRect = getStableRect(source)
    const left = sourceRect.left - rect.left
    const top = sourceRect.top - rect.top
    const scaleX = sourceRect.width / rect.width
    const scaleY = sourceRect.height / rect.height

    const transform = `translate(${left}px, ${top}px) scale(${scaleX}, ${scaleY})`
    const transformReverse =
      `translate(${left * -1}px, ${top * -1}px) scale(${1 / scaleX}, ${1 / scaleY})`

    source.style.transform = transformReverse
    sourceContent.style.transform = transform
    timeouts.set(() => {
      source.style.transform =
      sourceContent.style.transform = ''
    }, DURATION + 30)

    menu.style.transition = menuContent.style.transition = 'none'
    menu.style.transform = transform
    menuContent.style.transform = transformReverse
    menu.offsetHeight // force repaint
    menu.style.transition = menuContent.style.transition = ''
    menu.style.transform = menuContent.style.transform = ''

    background.style.transform = bgTransform
    background.style.opacity = 1
    arrow.style.transform = arrowTransform
  } else {
    const transform = 'rotateX(-15deg)'

    const fancyOut = () => {
      menu.style.transform = transform
      background.style.transform = [ bgTransform, transform ].join(' ')
      background.style.opacity = 0
      arrow.style.transform = `${arrowTransform} scale(0)`
    }

    const fancyIn = () => {
      menu.style.transform = 'none'
      background.style.display = 'none'
      background.offsetHeight
      background.style.transform = bgTransform
      background.style.display = ''
      background.offsetHeight
      background.style.opacity = 1
      arrow.style.transform = arrowTransform
    }

    if (reverse) fancyIn()
    else {
      menu.style.transition = 'none'
      fancyOut()
    }

    menu.offsetHeight
    background.offsetHeight
    menu.style.transition = ''

    if (reverse) {
      fancyOut()
      timeouts.set(() => menu.style.transform = '', DURATION + 30)
    } else fancyIn()
  }

  timeouts.set(() => {
    if (!navbar.querySelector('.show'))
      background.style.opacity = 0
  }, 30)
}
