const init = () => {
  // ARIA roles for when stacked
  document.querySelectorAll('.table-stacked')
  .forEach(table => table.setAttribute('role', 'table'))
  document.querySelectorAll('.table-stacked > caption')
  .forEach(caption => caption.setAttribute('role', 'caption'))
  document.querySelectorAll('.table-stacked > thead, .table-stacked > tbody, .table-stacked > tfoot')
  .forEach(rowgroup => rowgroup.setAttribute('role', 'rowgroup'))
  document.querySelectorAll('.table-stacked > :not(caption) > tr')
  .forEach(tr => tr.setAttribute('role', 'row'))
  document.querySelectorAll('.table-stacked > * > * > td')
  .forEach(td => td.setAttribute('role', 'cell'))
  document.querySelectorAll('.table-stacked > * > * > th')
  .forEach(th => th.setAttribute('role', 'columnheader'))
  document.querySelectorAll('.table-stacked > * > * > th[scope=row]')
  .forEach(th => th.setAttribute('role', 'rowheader'))

  // Cell labels
  document.querySelectorAll('.table-stacked').forEach(table =>
    table.querySelectorAll(':scope > thead > * > th').forEach((th, i) =>
      th.textContent &&
      table.querySelectorAll(`:scope > tbody > * > td:nth-of-type(${i + 1}):not([colspan])`)
      .forEach(td => td.setAttribute('data-cell', th.textContent))
    )
  )

  // Ensure one direct child per cell
  const nodesWidthContent = nodes => Array.from(nodes).filter(node => {
    switch (node.nodeType) {
    case Node.ELEMENT_NODE: return true
    case Node.TEXT_NODE: return node.nodeValue.trim()
    }
  })

  document.querySelectorAll('.table-stacked > :not(caption) > * > :not([colspan])')
  .forEach(cell => {
    if (nodesWidthContent(cell.childNodes).length > 1) {
      const div = document.createElement('div')
      while (cell.firstChild) div.appendChild(cell.firstChild)
      cell.appendChild(div)
    }
  })
}

document.addEventListener('DOMContentLoaded', init)
