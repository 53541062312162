import debounce from 'debounce'
import throttle from 'lodash.throttle'

// Normalized height for slides with arbitrary content
const normalizeHeight = carousel => {
  const slides = carousel.querySelectorAll('.carousel-item')

  slides.forEach(slide => {
    slide.style.height  = ''
    slide.style.display = 'block'
  })

  const heights = Array.prototype.map.call(slides, slide => slide.offsetHeight)
  const height = Math.max(...heights)

  slides.forEach(slide => {
    slide.style.height  = `${height}px`
    slide.style.display = ''
  })
}

const initNormalizedHeights = () => {
  const carousels = document.querySelectorAll('.carousel-normalized-height')
  if (!carousels.length) return

  const normalizeHeights = () => carousels.forEach(normalizeHeight)

  addEventListener('resize', debounce(normalizeHeights, 66))
  addEventListener('load', normalizeHeights)
  normalizeHeights()
}

document.addEventListener('DOMContentLoaded', initNormalizedHeights)

// Play/pause carousels depending on if they are in view
const isInViewport = element => {
  const rect = element.getBoundingClientRect()
  const threshold =
    Math.min(rect.height, document.documentElement.clientHeight) * 0.4

  return (
    rect.top + threshold >=
      Math.min(0, document.documentElement.clientHeight - rect.height) &&
    rect.bottom - threshold <=
      Math.max(document.documentElement.clientHeight, rect.height)
  )
}

const playPauseInView = carousel => {
  if (isInViewport(carousel))
    $(carousel).carousel('cycle')
  else
    $(carousel).carousel('pause')
}

const initInView = () => {
  const carousels = document.querySelectorAll('.carousel-play-in-view')
  if (!carousels.length) return

  const checkOnCarousels = () => carousels.forEach(playPauseInView)

  addEventListener('resize', debounce(checkOnCarousels, 66))
  addEventListener('scroll', throttle(checkOnCarousels, 66))
  addEventListener('load', checkOnCarousels)
  checkOnCarousels()
}

document.addEventListener('DOMContentLoaded', initInView)
