import throttle from 'lodash.throttle'
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

const initHorizontalScroll = scroll => {
  const scrollArea = scroll.querySelector('.horizontal-scroll-area')
  const prevButton = scroll.querySelector('.horizontal-scroll-button[href="#previous"]')
  const nextButton = scroll.querySelector('.horizontal-scroll-button[href="#next"]')

  const handleScroll = () => {
    if (scrollArea.scrollLeft) {
      prevButton.classList.remove('horizontal-scroll-button-disabled')
      prevButton.tabIndex = 0
    } else {
      prevButton.classList.add('horizontal-scroll-button-disabled')
      prevButton.tabIndex = -1
    }

    if (Math.round(scrollArea.offsetWidth + scrollArea.scrollLeft) < scrollArea.scrollWidth) {
      nextButton.classList.remove('horizontal-scroll-button-disabled')
      nextButton.tabIndex = 0
    } else {
      nextButton.classList.add('horizontal-scroll-button-disabled')
      nextButton.tabIndex = -1
    }
  }

  scrollArea.addEventListener('scroll', throttle(handleScroll, 66))
  handleScroll()

  const resizeObserver = new ResizeObserver(handleScroll)
  resizeObserver.observe(scrollArea)

  const handleButtonClick = page => e => {
    e.preventDefault()
    scrollArea.scrollBy({ left: scroll.offsetWidth * page, behavior: 'smooth' })
  }

  prevButton.addEventListener('click', handleButtonClick(-1))
  nextButton.addEventListener('click', handleButtonClick(1))
}

export const init = () =>
  document.querySelectorAll('.horizontal-scroll').forEach(initHorizontalScroll)

document.addEventListener('DOMContentLoaded', init)
