const popoverSelector = '[data-toggle="popover"],[data-bs-toggle="popover"]'
const tooltipSelector = '[data-toggle="tooltip"],[data-bs-toggle="tooltip"]'

const dismissPopovers = e => {
  const trigger = e.target.closest(popoverSelector)
  const popover = e.target.closest('.popover')

  document.querySelectorAll(popoverSelector).forEach(element => {
    if (trigger && element == trigger) return
    if (popover && element.getAttribute('aria-describedby') == popover.getAttribute('id')) return
    $(element).popover('hide')
  })
}

let startEvent

const wasTapped = endEvent => {
  const startTouch  = startEvent && startEvent.changedTouches[0]
  const endTouch    = endEvent && endEvent.changedTouches[0]

  return startEvent && endEvent && startTouch && endTouch &&
    endEvent.timeStamp - startEvent.timeStamp   <= 500 && // duration
    Math.abs(endTouch.pageX - startTouch.pageX) <= 10  && // deltaX
    Math.abs(endTouch.pageY - startTouch.pageY) <= 10     // deltaY
}

const handleTouchStart = e => startEvent = e
const handleTouchEnd = e => wasTapped(e) && dismissPopovers(e)

const initBS = () => {
  if (typeof $ == 'undefined') return

  $(tooltipSelector).tooltip()
  $(tooltipSelector.split(',').map(s => `.table-responsive ${s}`).join())
  .tooltip('dispose').tooltip({ boundary: 'window' })
  $(popoverSelector).on('click', e => e.preventDefault()).popover()

  document.addEventListener('click', dismissPopovers)
  document.addEventListener('touchstart', handleTouchStart, { passive: true })
  document.addEventListener('touchend', handleTouchEnd)
}

document.addEventListener('DOMContentLoaded', initBS)
