let rem = 16

const getRem = () =>
  parseFloat(getComputedStyle(document.documentElement).fontSize)

const setRem = () => { rem = getRem() }

try {
  setRem()
} catch (e) {
  document.addEventListener('DOMContentLoaded', setRem)
}

const remToPx = (qty = 1) => rem * qty

export default remToPx
