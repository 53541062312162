import ClipboardJS from 'clipboard'

const showTooltip = (btn, message) => {
  $(btn).tooltip('hide')
    .attr('data-original-title', message)
    .tooltip('show')
  setTimeout(() => $(btn).tooltip('hide'), 1000)
}

const initClipboardBS4 = () => {
  const btn = '[data-clipboard-target]'
  const clipboard = new ClipboardJS(btn)

  $(btn).tooltip({ trigger: 'manual', placement: 'bottom' })

  clipboard.on('success', e => {
    showTooltip(e.trigger, 'Copied to clipboard!')
    e.clearSelection()
  })

  clipboard.on('error', e => {
    const key = navigator.platform == 'MacIntel' ? 'Cmd' : 'Ctrl'
    showTooltip(e.trigger, `Press ${key}+C to copy`)
  })
}

const showTooltipBS5 = (btn, title) => {
  const options = { trigger: 'manual', placement: 'bottom', title }
  const tooltip = bootstrap.Tooltip.getOrCreateInstance(btn, options)
  tooltip.hide()
  tooltip.setContent({ '.tooltip-inner': title })
  tooltip.show()
  setTimeout(() => tooltip.hide(), 1000)
}

const initClipboardBS5 = () => {
  const clipboard = new ClipboardJS('[data-clipboard-target]')

  clipboard.on('success', e => {
    showTooltipBS5(e.trigger, 'Copied to clipboard!')
    e.clearSelection()
  })

  clipboard.on('error', e => {
    const key = navigator.platform == 'MacIntel' ? 'Cmd' : 'Ctrl'
    showTooltipBS5(e.trigger, `Press ${key}+C to copy`)
  })
}

const initClipboard = () =>
  bootstrap.Tooltip?.VERSION[0] == '5' ? initClipboardBS5() : initClipboardBS4()

document.addEventListener('DOMContentLoaded', initClipboard)

export default initClipboard
