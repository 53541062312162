import { MENU_SELECTOR, TOGGLE_SELECTOR, OFFCANVAS_SELECTOR, DURATION } from './constants'
import { makeFancy, resetFancy } from './fancy'

const navbarExpanded = () => document.documentElement.clientWidth >= 992

let recentTouch

const canMakeFancy = () =>
  !recentTouch && navbarExpanded() && !matchMedia('(prefers-reduced-motion)').matches

const handleTouchEnd = () => {
  resetFancy()
  recentTouch = true
  setTimeout(() => recentTouch = false, DURATION)
}

const dropdown = (toggle, action) =>
  bootstrap.Dropdown.getOrCreateInstance
  ? bootstrap.Dropdown.getOrCreateInstance(toggle)[action]()
  : $(toggle).dropdown(action)

const handleMouseEnter = e => {
  if (navbarExpanded() && !recentTouch) {
    const toggle = e.target.closest(TOGGLE_SELECTOR)
    const focused = toggle.matches(':focus')
    dropdown(toggle, 'show')
    if (!focused) toggle.blur()
  }
}

const handleMouseLeave = e => {
  if (navbarExpanded() && !recentTouch)
    dropdown(e.target.querySelector(TOGGLE_SELECTOR), 'hide')
}

const handleShown = e => {
  const toggle = e.target.closest(TOGGLE_SELECTOR)
  const menu = e.target.closest('.dropdown').querySelector('.dropdown-menu')
  clearTimeout(menu.hideTimeout)
  if (canMakeFancy()) makeFancy(toggle, menu)
  menu.offsetHeight // force repaint
  menu.classList.add('shown')

  const offcanvas = e.target.closest(OFFCANVAS_SELECTOR)
  clearTimeout(offcanvas.hideTimeout)
  offcanvas.classList.add('has-show')
  offcanvas.offsetHeight // force repaint
  offcanvas.classList.add('has-shown')
}

const handleHidden = e => {
  const toggle = e.target.closest(TOGGLE_SELECTOR)
  const menu = e.target.closest('.dropdown').querySelector('.dropdown-menu')
  if (canMakeFancy()) makeFancy(toggle, menu, { reverse: true })
  menu.hideTimeout = setTimeout(() => menu.classList.remove('shown'), DURATION + 30)

  const offcanvas = e.target.closest(OFFCANVAS_SELECTOR)
  offcanvas.classList.remove('has-show')
  offcanvas.hideTimeout = setTimeout(() =>
    offcanvas.classList.remove('has-shown')
  , DURATION + 30)
}

document.addEventListener('touchend', handleTouchEnd)
document.querySelectorAll(`${MENU_SELECTOR} .dropdown`).forEach(dropdown => {
  dropdown.addEventListener('mouseleave', handleMouseLeave)
})
document.querySelectorAll(TOGGLE_SELECTOR).forEach(toggle => {
  toggle.addEventListener('mouseenter', handleMouseEnter)
  toggle.addEventListener('hidden.bs.dropdown', handleHidden)
  toggle.addEventListener('shown.bs.dropdown', handleShown)
})

// Hide FreshChat when offcanvas is shown
document.querySelectorAll(OFFCANVAS_SELECTOR).forEach(offcanvas => {
  offcanvas.addEventListener('show.bs.offcanvas', () =>
    document.querySelectorAll('.fc-widget-normal')
    .forEach(w => w.classList.add('d-none'))
  )
  offcanvas.addEventListener('hidden.bs.offcanvas', () =>
    document.querySelectorAll('.fc-widget-normal')
    .forEach(w => w.classList.remove('d-none'))
  )
})
